<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            type="year"
            default="today"
            label="대상년도"
            name="year"
            v-model="searchParam.year"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="MAIN_SAFETY_HEALTHY_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="safetyHealthyTypeCd"
            label="구분"
            v-model="searchParam.safetyHealthyTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="관계법령 의무사항 이행 계획/실적"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPop" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-healthy-target',
  data() {
    return {
      searchParam: {
        plantCd: null,
        year: '',
        safetyHealthyTypeCd: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'targetYear',
            field: 'targetYear',
            label: '대상년도',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
          {
            name: 'safetyHealthyTypeName',
            field: 'safetyHealthyTypeName',
            label: '구분',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'targetTitle',
            field: 'targetTitle',
            label: '제목',
            align: 'left',
            sortable: true,
            style: 'width:200px',
            type: 'link'
          },
          {
            name: 'writeDeptName',
            field: 'writeDeptName',
            label: '작성부서',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'writeDt',
            field: 'writeDt',
            label: '작성일',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.mainsafetyHealthy.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '관계법령 의무사항 이행 계획/실적 상세'; 
      this.popupOptions.param = {
        mainSafetyHealthyTargetId: row ? row.mainSafetyHealthyTargetId : '',
      };
      this.popupOptions.target = () => import(`${'./mainSafetyHealthyTargetDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
